var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "space-listing",
      class: ["space-listing--" + this.variant],
      on: {
        click: function ($event) {
          return _vm.switchAreaHandler(_vm.space.id)
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "space-listing-cover",
          style: {
            "--background-color": _vm.space.primaryColour,
          },
        },
        [
          _vm.isNormalVariant
            ? _c("GenericMenu", {
                staticClass: "space-listing-cover__menu",
                attrs: {
                  items: [
                    {
                      icon: "delete",
                      text: "Remove me from Space",
                      click: () => _vm.handleLeaveSpace(_vm.space.id),
                    },
                  ],
                },
              })
            : _vm._e(),
          _vm.coverImage
            ? _c("img", {
                staticClass: "space-listing-cover__image",
                attrs: {
                  src: _vm.coverImage,
                  loading: "lazy",
                  alt: "Space cover image",
                },
              })
            : _vm._e(),
          _c("SpaceLogo", {
            staticClass: "space-listing-logo",
            attrs: { logo: _vm.logo, title: _vm.title, diameter: "120px" },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "space-listing-text" }, [
        _c("h2", { staticClass: "space-listing-text__title" }, [
          _vm._v(_vm._s(_vm.space.title)),
        ]),
        _c("div", { staticClass: "space-listing-text__company" }, [
          _vm._v(_vm._s(_vm.space.companyName)),
        ]),
        _vm.space.info.heading
          ? _c("div", { staticClass: "space-listing-text__info-title" }, [
              _vm._v(_vm._s(_vm.space.info.heading)),
            ])
          : _vm._e(),
        _vm.space.info.description
          ? _c("div", { staticClass: "space-listing-text__info-text" }, [
              _vm._v(_vm._s(_vm.space.info.description)),
            ])
          : _vm._e(),
      ]),
      _vm.isNormalVariant
        ? _c("div", { staticClass: "space-listing-online" }, [
            _c("img", {
              staticClass: "space-listing-online__icon",
              attrs: { src: require("@/assets/user-icon-white.svg") },
            }),
            _c("div", { staticClass: "space-listing-online__number" }, [
              _vm._v(_vm._s(_vm.space.online)),
            ]),
            false
              ? _c("div", { staticClass: "space-listing-online__live" }, [
                  _vm._v("LIVE"),
                ])
              : _vm._e(),
            _c("img", {
              staticClass: "space-listing-online__arrow",
              attrs: { src: require("@/assets/secondary-menu-arrow.svg") },
            }),
          ])
        : _vm._e(),
      _vm.isInviteVariant
        ? _c(
            "div",
            { staticClass: "space-listing-invite-buttons" },
            [
              _c("btn", {
                attrs: { disabled: _vm.processingInvite, colour: "ghost" },
                on: { onClick: _vm.dismissInvite },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "text",
                      fn: function () {
                        return [_vm._v("Dismiss")]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2220467130
                ),
              }),
              _c("btn", {
                attrs: { disabled: _vm.processingInvite, colour: "green" },
                on: { onClick: _vm.acceptInvite },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "text",
                      fn: function () {
                        return [_vm._v("Accept invitation")]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1736672081
                ),
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }